import { IconButton } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Router from "next/router";

const GoBackIconButton = ({ onClick }: { onClick?: Function }) => {
  return (
    <div className="flex self-center">
      <IconButton
        onClick={() => {
          if (typeof onClick === "function") {
            onClick();
          } else {
            Router.back();
          }
        }}>
        <ArrowBack />
      </IconButton>
    </div>
  );
};

export default GoBackIconButton;
