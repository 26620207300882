import { Provider, useAppContext } from "@components/context";
import BottomNavigation from "@components/m/BottomNavigation";
import { AppBar, Slide, Toolbar, useScrollTrigger } from "@material-ui/core";
import { useProgressBar } from "@utils/hooks/use-progress-bar";
import type { ReactNode } from "react";
import React, { useState } from "react";

const HideOnScroll = ({
  children,
  window,
}: {
  children: React.ReactElement;
  window?: () => Window;
}) => {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={true} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

type IHeadProps = {
  LeftComponent?: React.ReactNode;
  RightComponent?: React.ReactNode;
  headerTitle?: string;
};

export const MobileHeader = React.forwardRef((props: IHeadProps, ref) => {
  const { LeftComponent, RightComponent, headerTitle } = props;
  return (
    <AppBar
      color="inherit"
      classes={{
        root: "bg-white",
      }}
      elevation={0}
      ref={ref}>
      <Toolbar>
        <div className="grid grow grid-cols-3 items-center gap-x-2">
          {LeftComponent}
          <div className="col-start-2 grow text-center text-xl font-medium">
            {headerTitle}
          </div>
          {RightComponent}
        </div>
      </Toolbar>
    </AppBar>
  );
});

MobileHeader.displayName = "MobileHeader";

function HideHeader(props) {
  return (
    <>
      <HideOnScroll>
        <MobileHeader {...props} />
      </HideOnScroll>
      <Toolbar />
    </>
  );
}

const Mobile = ({ children, ...props }) => {
  const { hideBottomNav } = props;
  const baseContext = useAppContext();
  const { LinearProgress } = useProgressBar();
  const [LeftComponent, setLeftComponent] = useState<ReactNode>(null);
  const [RightComponent, setRightComponent] = useState<ReactNode>(null);

  const academyContext = {
    ...baseContext,
    setLeftComponent,
    setRightComponent,
  };

  return (
    <Provider value={academyContext}>
      <HideHeader
        {...props}
        LeftComponent={LeftComponent}
        RightComponent={RightComponent}
      />
      <div className="absolute top-[64px] h-1 w-full">
        <LinearProgress />
      </div>
      <div className="mb-[56px] h-[calc(100%_-_124px)]">
        {children}
        {hideBottomNav ? null : <div className="h-[56px] w-full" />}
      </div>
      {hideBottomNav ? null : <BottomNavigation />}
    </Provider>
  );
};

export default Mobile;
