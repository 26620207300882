import { displayUnauthorized } from "@components/AppEnv";
import { useAppContext } from "@components/context";
import MoreIconSVG from "@components/SvgIcon/MoreIconSVG";
import MyJournalSVG from "@components/SvgIcon/MyJournalSVG";
import { Paper } from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { isTokenAvailable } from "@utils/auth-utils";
import Router from "next/router";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";

const MyBottomNavigation = () => {
  const intl = useIntl();
  const { bottomTab, setBottomTab, loginStatus } = useAppContext();

  useEffect(() => {
    const { pathname } = Router;
    const path = pathname.split("/")[2];
    if (path === "explore") {
      setBottomTab(0);
    } else if (path === "myJournal") {
      setBottomTab(1);
    } else if (path === "notification") {
      setBottomTab(2);
    } else if (path === "more") {
      setBottomTab(3);
    }
  }, [Router]);

  const hasToken = isTokenAvailable(loginStatus.token) === true;
  const hasTokenAndDisplayUnauthorized = hasToken || displayUnauthorized;

  return (
    <Paper className="fixed inset-x-0 bottom-0 pb-safe" elevation={0}>
      <BottomNavigation
        className="border-t border-solid border-gray02"
        value={bottomTab}
        onChange={(event: React.ChangeEvent<HTMLButtonElement>) => {
          const { name } = event.currentTarget;
          Router.push({ pathname: `/m/${name}` });
        }}
        showLabels>
        {hasTokenAndDisplayUnauthorized ? (
          <BottomNavigationAction
            name="explore"
            label={intl.formatMessage({ id: "common.header.explore" })}
            icon={<ExploreOutlinedIcon className="!text-2xl" />}
          />
        ) : null}
        {hasToken ? (
          <BottomNavigationAction
            name="myJournal"
            label={intl.formatMessage({ id: "common.header.myJournal" })}
            icon={<MyJournalSVG className="!text-2xl" />}
          />
        ) : null}
        {hasToken ? (
          <BottomNavigationAction
            name="notification"
            label={intl.formatMessage({
              id: "common.header.notification.notification",
            })}
            icon={<NotificationsNoneOutlinedIcon className="!text-2xl" />}
          />
        ) : null}
        <BottomNavigationAction name="more" icon={<MoreIconSVG />} />
      </BottomNavigation>
    </Paper>
  );
};

export default MyBottomNavigation;
