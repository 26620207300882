import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M19.07 3H5.462a2.33 2.33 0 0 0-1.663.662A2.26 2.26 0 0 0 3.125 5.3v13.5a2.213 2.213 0 0 0 .703 1.579A2.282 2.282 0 0 0 5.461 21h13.711a2.381 2.381 0 0 0 1.644-.681c.437-.43.685-1.011.692-1.619V5.3a2.397 2.397 0 0 0-.752-1.629A2.472 2.472 0 0 0 19.07 3Zm0 16H5.462a.273.273 0 0 1-.228-.075.266.266 0 0 1-.076-.225V5.3a.261.261 0 0 1 .076-.225A.27.27 0 0 1 5.46 5h13.711c.102 0 .203.1.203.3v13.5c0 .1-.101.2-.304.2Z"
      fill="currentColor"
    />
    <path
      d="m4.75 14.2 3.148-3.1v-.3c0-.45.182-.883.506-1.202A1.74 1.74 0 0 1 9.625 9.1c.458 0 .897.18 1.22.498.325.319.507.751.507 1.202v.3l1.523 1.5h.61l2.234-2.2v-.3c0-.45.182-.883.505-1.202a1.74 1.74 0 0 1 1.221-.498c.458 0 .897.18 1.221.498.324.319.506.751.506 1.202a1.606 1.606 0 0 1-.483 1.225 1.656 1.656 0 0 1-1.244.475h-.304L14.703 14v.3c0 .45-.182.883-.506 1.202a1.74 1.74 0 0 1-1.22.498 1.74 1.74 0 0 1-1.221-.498 1.687 1.687 0 0 1-.506-1.202V14l-1.523-1.5h-.61l-4.469 4.4.102-2.7Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
