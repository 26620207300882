import * as React from "react";
import { memo } from "react";

const SvgComponent = (props) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16.25 1.333c2.773 0 5.484.86 7.79 2.472 2.305 1.611 4.102 3.902 5.163 6.582A15.297 15.297 0 0 1 30 18.861c-.541 2.845-1.877 5.459-3.837 7.51-1.961 2.051-4.46 3.448-7.179 4.014-2.72.566-5.538.276-8.1-.835-2.562-1.11-4.751-2.99-6.292-5.402A15.152 15.152 0 0 1 2.23 16c.011-3.886 1.492-7.61 4.119-10.359 2.626-2.748 6.186-4.297 9.901-4.308Zm0-1.333c-3.025 0-5.982.938-8.497 2.696C5.238 4.455 3.278 6.953 2.12 9.877a16.687 16.687 0 0 0-.87 9.244c.59 3.104 2.047 5.955 4.186 8.193 2.139 2.237 4.864 3.761 7.83 4.379 2.967.617 6.042.3 8.837-.91 2.795-1.212 5.183-3.263 6.864-5.894A16.53 16.53 0 0 0 31.544 16a16.605 16.605 0 0 0-1.154-6.129 16.03 16.03 0 0 0-3.315-5.196 15.23 15.23 0 0 0-4.966-3.467A14.674 14.674 0 0 0 16.25 0Z"
      fill="currentColor"
    />
    <path
      d="M7.816 20.3v2.2h16.92v-2.2H7.815Zm0-5.4v2.2h16.92v-2.2H7.815Zm0-5.4v2.2h16.92V9.5H7.815Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
